@import '../../styles/customMediaQueries.css';

.root {
}

.topbarContent {
  max-width: var(--marketplacePageWidth);
  margin: 0 auto;

  padding: 0 24px;
}

.pageRoot {
  display: flex;
}

/* Content */
.contentContainer {
  display: grid;
  grid-template-columns: 1fr;

  max-width: var(--marketplacePageWidth);
  margin: 0 auto 48px;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0;
  }

  @media (--viewportLargeWithPaddings) {
    grid-template-columns: 1fr 380px;
    grid-gap: 48px;

    margin: 0 auto 96px;
    padding: 0 24px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.asideContent {
}

/* Modals */
.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
  border-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.modalButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Style */
  background-color: var(--marketplaceColor);
  margin-top: 32px;

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.descriptionModalContainer {
  composes: marketplaceModalBaseStyles from global;

  @media (--viewportLarge) {
    padding: 72px 48px 48px;
  }
}

/* .SectionHero */
.sectionHero {
  /* Bg */
  background-image: linear-gradient(to left top, #000000, #000000, #320327, #320327);
}

.heroContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  max-width: var(--marketplacePageWidth);
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  @media (--viewportMedium) {
    height: auto;
    padding: 24px;
  }

  @media (--viewportLarge) {
    padding: 48px 24px;
  }
}

.titleContainer {
  /* Position */
  position: relative;

  /* Layout */
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: experiencePageHeroTitle from global;
  color: var(--matterColorLight);

  /* Layout */
  margin: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.topContent {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding-bottom: 24px;

  @media (--viewportMedium) {
    padding-bottom: 48px;
  }
}

.returnLink {
  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
  text-underline-position: auto;

  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Effect */
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    &:hover {
      opacity: 0.8;
    }
  }
}

.returnLinkIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
  margin-top: 2px;
}

/* Top reviews */
.topReviews {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  /* Size */
  width: 100%;

  /* Margin & Padding */
  margin-top: 24px;
  padding-top: 24px;

  /* Style */
  border-top: 1px solid var(--matterColorLight);

  @media (--viewportMedium) {
    gap: 32px;
  }

  @media (--viewportLarge) {
    width: calc(100% - var(--showPanelWidth) - 48px);
  }
}

.topReviewsSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.topReviewsHeading {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightMedium);
}

.topReviewsValue {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  /* Font */
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  font-size: 24px;
  line-height: 32px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 42px;
    line-height: 48px;
  }
}

.showRatingStar {
  fill: var(--marketplaceColor);
}

/* SectionReviews */
.sectionReviews {
  margin-top: 48px;

  @media (--viewportMedium) {
    /* Reset */
    margin-top: 48px;
  }
}

.sectionReviewsWithButton {
  margin-bottom: 0;
}

.reviewsHeadingContainer {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Margin */
  margin-top: 0;
  margin-bottom: 24px;
}

.reviewsHeading {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0;
}

.reviewButton {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 12px;

  /* Size */
  height: 36px;
  padding: 0 16px;
  border-radius: calc(var(--borderRadiusMedium) * 2 + 2px);

  /* Font */
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);

  /* Effect */
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColorLight);
    color: var(--matterColorLight);
    text-decoration: none;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.sectionSimilarShows {
  margin-top: 48px;

  /* Aside style */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadiusMedium);
  padding: 24px;
}

.similarShowsHeading {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 24px;
}

.verticalListingCard {
  display: flex;
  align-items: flex-start;

  /* Reset */
  color: var(--matterColor);
  text-decoration: none !important;

  /* Style */
  background: var(--matterColorBright);
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);

  &:nth-child(2n) {
    background: var(--matterColorLight);
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  /* Effect */
  transition: var(--transitionStyleButton);
}

.verticalListingCardImageContainer {
  /* Layout */
  position: relative;

  /* Style */
  border-radius: var(--borderRadiusMedium);

  &:after {
    content: '';

    /* Position */
    position: absolute;
    top: 0;
    left: 0;

    /* Size */
    height: 100%;
    width: 100%;

    /* Style */
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: var(--borderRadiusMedium);
    opacity: 0;

    /* Effect */
    transition: opacity 0.15s;
  }

  @media (--viewportMedium) {
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
}

.verticalListingCardRootImage {
  height: 86px;
  width: 86px;

  object-fit: cover;
  border-radius: var(--borderRadiusMedium);
}

.verticalListingCardContent {
  padding: 12px;
}

.verticalListingCardTitle {
  margin-top: 0;
  margin-bottom: 0;

  /* Limit text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.verticalListingCardSubTitle {
  composes: marketplaceTinyFontStyles from global;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;
}
